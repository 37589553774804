<template>
  <div>
    <va-input
      class="mb-3"
      v-model="data.password"
      type="password"
      :label="$t('user.form.new_password')"
      :valid="[data.length > 0 || $t('form.error.field_required')]"
      @update:model-value="value => update(value, 'password')"
    />
    <va-input
      class="mb-3"
      v-model="data.confirmPassword"
      type="password"
      :label="$t('user.form.confirm_password')"
      :valid="[data.password != data.confirmPassword || $t('user.password_do_not_match')]"
      @update:model-value="value => update(value, 'confirmPassword')"
    />
  </div>
</template>

<script>
import { reactive } from 'vue';
export default {
  name: 'reset-password-fields',
  setup: (props, { emit }) => {
    const data = reactive({ password: '', confirmPassword: '' });
    const update = (value, key) => {
      const newData = { ...data };
      newData[key] = value;
      emit('update', newData);
    };
    return {
      data,
      update
    };
  }
};
</script>
