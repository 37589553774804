<template>
  <va-inner-loading
    :loading="loading | readOnly"
    color="primary"
    :icon="readOnly ? '' : 'autorenew'"
  >
    <va-form ref="form" name="section_address">
      <va-input
        class="mb-3"
        :label="$t('user.form.first_name')"
        v-model="data.firstName"
        :rules="[useGetRules({ required: true }).rules]"
        id="first-name"
        name="first-name"
      />
      <va-input
        class="mb-3"
        :label="$t('user.form.last_name')"
        v-model="data.lastName"
        :rules="[useGetRules({ required: true }).rules]"
        id="last-name"
        name="last-name"
      />
      <va-input
        class="mb-3"
        :label="$t('user.form.phone_number')"
        v-model="data.phoneNumber"
        :returnRaw="true"
        :mask="{ phone: true, phoneRegionCode: 'FR' }"
        :rules="[useGetRules({ required: true }).rules, phoneNumberRules]"
        id="phone-number"
        name="phone-number"
      />
      <va-input
        class="mb-3"
        :label="$t('user.form.email')"
        v-model="data.email"
        :rules="[useGetRules({ required: true }).rules, validateEmail]"
        id="email"
        name="email"
      />
      <va-select
        class="mb-4"
        :label="$t('user.form.roles')"
        :options="roles"
        v-if="isAdmin"
        :text-by="(option) => option.label"
        :value-by="(option) => option.key"
        :track-by="(option) => option.key"
        v-model="data.roles"
        id="roles"
        name="roles"
        :rules="[useGetRules({ required: true }).rules]"
      />
      <div v-if="data.roles.indexOf(UserRolesEnum.AGENCY) > -1">
        <va-input
          class="mb-3"
          :label="$t('user.form.agency')"
          v-model="data.agency"
          id="agency"
          name="agency"
        />
        <va-select
          class="mb-4"
          :label="$t('user.form.contacts')"
          :options="traders"
          :text-by="(option) => option.email"
          :track-by="(option) => option._id"
          v-model="data.contacts"
          multiple
          searchable
          id="contacts"
          name="contacts"
          :disabled="!isAdmin || getTraderLoading"
          :rules="[useGetRules({ required: true }).rules]"
        />
        <va-alert
          id="error-duplicate-contact"
          color="danger"
          class="mb-4"
          v-if="errorContacts"
        >
          <template #icon>
            <va-icon name="warning" />
          </template>
          {{ errorContacts }}
        </va-alert>
      </div>
      <div v-if="editCurrentUser">
        <reset-password-fields @update="updatePassword" />
      </div>
      <va-button
        size="large"
        data-cy="submit-button"
        @click="handleSubmit"
        color="secondary"
        :disabled="!isValid"
      >
        {{ $t("form.submit") }}
      </va-button>
    </va-form>
  </va-inner-loading>
</template>

<script>
import {
  GET_USERS,
  UserRolesEnum,
  OperationEnum,
  OperatorEnum,
} from "@mdb/core";
import { useToast } from "vue-toastification";
import { useMutation } from "@vue/apollo-composable";
const toast = useToast();
import { useI18n } from "vue-i18n";
import { reactive, ref, toRefs, watch, computed } from "vue";
import useGetRules from "@/components/form/useGetRules";
import useGetList from "@/components/helpers/useGetList";
import "cleave.js/dist/addons/cleave-phone.fr.js";
import AuthenticationService from "@/components/helpers/AuthenticationService";
import ResetPasswordFields from "@/pages/auth/ResetPasswordFields";
import { useRoute } from "vue-router";
export default {
  props: {
    readOnly: { type: Boolean, default: false },
    loading: { type: Boolean, default: false },
    submitAction: Function,
    initialData: {
      type: Object,
      default: () => ({
        firstName: "",
        lastName: "",
        phoneNumber: "",
        email: "",
        agency: "",
        roles: "",
        contacts: [],
        newPassword: "",
      }),
    },
  },
  components: { ResetPasswordFields },
  setup(props) {
    const { t } = useI18n({ useScope: "global" });

    const route = useRoute();

    const form = ref("propertyForm");

    const isAdmin = computed(() => AuthenticationService.isAdmin());

    const errorContacts = ref("");

    // for the contact list
    const filterTrader = {
      operator: OperatorEnum.AND,
      filters: [
        {
          field: "roles",
          values: [UserRolesEnum.TRADER],
          operation: OperationEnum.IN,
        },
      ],
    };

    const phoneNumberRules = (value) => {
      if (value.length != 10 || value.charAt(0) != "0") {
        return t("user.form.error.phone_number");
      }
    };

    const queryOptions = ref({
      enabled: isAdmin,
      notifyOnNetworkStatusChange: true,
    });

    const {
      items: traders,
      loading: getTraderLoading,
      error,
    } = useGetList(null, null, filterTrader, GET_USERS, queryOptions);

    const filterAgencies = {
      operator: OperatorEnum.AND,
      filters: [
        {
          field: "roles",
          values: [UserRolesEnum.AGENCY],
          operation: OperationEnum.IN,
        },
      ],
    };

    const { items: agencies, loading: getAgencyLoading } = useGetList(
      null,
      null,
      filterAgencies,
      GET_USERS,
      queryOptions
    );

    const updatePassword = (value) => {
      data.newPassword = value.password;
    };

    /**
     * Verify if were are in edit mode and the use is the current user
     */
    const editCurrentUser = computed(() => {
      // were are in the account update, the initialdata is not empty but there is no id precise in the url
      const updateMe = !route.params.id && props.initialData.firstName;

      // we are in update user with an id in the url, and the id correspond to the current user
      const updateCurrentUser =
        route.params.id &&
        route.params.id == AuthenticationService.currentUser().id;
      return updateMe || updateCurrentUser;
    });

    const roles = Object.keys(UserRolesEnum).map((elt) => ({
      key: UserRolesEnum[elt],
      label: t(`user_roles.${UserRolesEnum[elt]}`),
    }));

    // define form
    const data = reactive({ ...props.initialData });
    watch(
      () => data.contacts,
      (newContacts, prevContacts) => {
        if (!newContacts) {
          errorContacts.value = "";
          return;
        }
        const userDuplicate = newContacts.filter((contact) =>
          agencies.value.find((agency) =>
            agency.contacts.find((elt) => contact._id == elt._id)
          )
        );
        if (userDuplicate.length > 0) {
          errorContacts.value = t("user.form.error.contact_already_used", {
            emails: userDuplicate.map((elt) => elt.email).join(", "),
          });
        } else {
          errorContacts.value = "";
        }
      }
    );

    const validateEmail = (email) => {
      const correct = String(email)
        .toLowerCase()
        .match(
          /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/
        );
      if (!correct) {
        return t("form.error.email");
      }
      return true;
    };

    watch(
      () => data,
      (value, oldValue) => {}
    );

    const isValid = () => {
      return form.value.validate();
    };

    // create incident
    const handleSubmit = () => {
      if (form.value.validate()) {
        const object = {
          ...data,
          contacts: data.contacts ? data.contacts.map((elt) => elt._id) : [],
        };
        delete object._id;
        delete object.__typename;
        delete object.createdAt;
        delete object.fullName;
        props.submitAction(object);
      }
    };

    return {
      data,
      roles,
      form,
      traders,
      handleSubmit,
      isValid,
      useGetRules,
      UserRolesEnum,
      validateEmail,
      isAdmin,
      editCurrentUser,
      updatePassword,
      getTraderLoading,
      errorContacts,
      phoneNumberRules,
    };
  },
};
</script>

<style lang="scss"></style>
